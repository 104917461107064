var self;
export default {
  init: function() {
    self = this;
    self.initAnchor();
  },

  initAnchor: function() {
    $(".js_scroll-to").on("click", function(e) {
      e.preventDefault();
      let $anchor = $(this),
        target = $anchor.attr("data-target"),
        trigger = $anchor.attr("data-trigger");
      self.triggerAnchor(target, trigger, trigger != null);
    });
  },

  triggerAnchor: function(target, trigger, clickOnTrigger) {
    self = this;
    let topPosition = $("#" + target).position().top,
      stickyNaviSpace = $.SnsStickyNaviCollector().getSpaceAbove(topPosition),
      metaNaviSpace = $("#header-and-navi").height();

    if (clickOnTrigger) {
      if (trigger.startsWith("mobile-")) {
        $("#" + trigger + " .mobileToggleButton").trigger("click");
      } else {
        $("#" + trigger).trigger("click");
      }
    }
    self.scrollTo(topPosition - stickyNaviSpace - metaNaviSpace);
  },

  /**
   * animated scrolling to given position
   *
   * @param {number} scrollPosition
   */
  scrollTo: function(scrollPosition) {
    $("body, html").animate(
      {
        scrollTop: scrollPosition
      },
      2000
    );
  }
};
