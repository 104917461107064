import global from "../global";
import "../lib/jquery.history";
import lazyload from "../modules/lazyload";
import rangeFilterSlider from "../modules/rangeFilterSlider";
import "../modules/producthover";
import "jquery-ui/ui/widgets/autocomplete";

let navigationListPlaceHolderDesktop;
let navigationListPlaceHolderMobile;

let mediaQueryList;
let mobileFiltersPlaceHolder;
let desktopFiltersPlaceHolder;
let currentFiltersPlaceHolder;
let filtersDialogTrigger;
let filtersDialog;
let filtersDialogCloseButton;
let resetFiltersButton;
let applyFiltersButton;
let oldStateTemplate;
let curStateTemplate;

let mobileSortItemsPlaceHolder;
let desktopSortItemsPlaceHolder;
let currentSortItemsPlaceHolder;
let sortItemsDialogTrigger;
let sortItemsDialog;
let sortItemsDialogCloseButton;

let historyState;
let filterChanged;
let latestUrl;
let canOpenFilterDialog;
let executeRemoveFilterOnce;

const onBreakPointChange = event => {
  if (event.matches) {
    // mobile -> desktop
    currentFiltersPlaceHolder = desktopFiltersPlaceHolder;
    currentSortItemsPlaceHolder = desktopSortItemsPlaceHolder;

    const node = navigationListPlaceHolderMobile.querySelector(
      "ul.navigationList"
    );
    if (node) {
      navigationListPlaceHolderDesktop.appendChild(node);
    }

    if (!desktopFiltersPlaceHolder.childElementCount) {
      while (mobileFiltersPlaceHolder.firstElementChild) {
        desktopFiltersPlaceHolder.appendChild(
          mobileFiltersPlaceHolder.firstElementChild
        );
      }
    }

    if (!desktopSortItemsPlaceHolder.childElementCount) {
      while (mobileSortItemsPlaceHolder.firstElementChild) {
        desktopSortItemsPlaceHolder.appendChild(
          mobileSortItemsPlaceHolder.firstElementChild
        );
      }
    }

    if (filtersDialog.open) {
      cancelFilterDialog();
    }
  } else {
    // desktop -> mobile
    currentFiltersPlaceHolder = mobileFiltersPlaceHolder;
    currentSortItemsPlaceHolder = mobileSortItemsPlaceHolder;

    const node = navigationListPlaceHolderDesktop.querySelector(
      "ul.navigationList"
    );
    if (node) {
      navigationListPlaceHolderMobile.appendChild(node);
    }

    if (!mobileFiltersPlaceHolder.childElementCount) {
      while (desktopFiltersPlaceHolder.firstElementChild) {
        mobileFiltersPlaceHolder.appendChild(
          desktopFiltersPlaceHolder.firstElementChild
        );
      }
    }

    if (!mobileSortItemsPlaceHolder.childElementCount) {
      while (desktopSortItemsPlaceHolder.firstElementChild) {
        mobileSortItemsPlaceHolder.appendChild(
          desktopSortItemsPlaceHolder.firstElementChild
        );
      }
    }
  }
};

const openFilterDialog = () => {
  if (canOpenFilterDialog) {
    canOpenFilterDialog = false;

    filtersDialog.showModal();
    $(filtersDialog).addClass("animate");
    filtersDialogCloseButton.addEventListener("click", cancelFilterDialog);
    resetFiltersButton.addEventListener("click", resetFilters);
    applyFiltersButton.addEventListener("click", applyFilters);

    latestUrl = window.location.href;
    filterChanged = false;
    createFiltersBackup();
  }
};

const cancelFilterDialog = () => {
  if (!$(".js-select-filter").hasClass("is-disabled")) {
    applyFiltersButton.removeEventListener("click", applyFilters);
    resetFiltersButton.removeEventListener("click", resetFilters);
    filtersDialogCloseButton.removeEventListener("click", cancelFilterDialog);

    curStateTemplate = undefined;

    if (oldStateTemplate) {
      const productListPart = document.getElementById("product-list-part");
      productListPart.innerHTML =
        oldStateTemplate.content.firstElementChild.innerHTML;

      let title = oldStateTemplate.content.firstElementChild.title;
      let href = oldStateTemplate.content.firstElementChild.location;
      if (filterChanged) {
        self.pushStateWithInitCallback(title, href);
      } else {
        self.pushState(title, href);
        setTimeout(initFilters);
        setTimeout(showPlpTextImageTeaserToggle);
      }

      canOpenFilterDialog = true;
      oldStateTemplate = undefined;
      filtersDialog = document.getElementById("filters-dialog");
    }
    filtersDialog.close();
    $(filtersDialog).removeClass("animate");
    filterChanged = false;
  }
};

const showPlpTextImageTeaserToggle = () => {
  $(".js_toggle_prev_class").show();
};

const openSortItemsDialog = () => {
  sortItemsDialog.showModal();
  $(sortItemsDialog).addClass("animate");
  sortItemsDialogCloseButton.addEventListener("click", cancelSortItemsDialog);
};

const cancelSortItemsDialog = () => {
  sortItemsDialogCloseButton.removeEventListener(
    "click",
    cancelSortItemsDialog
  );
  sortItemsDialog.close();
  $(sortItemsDialog).removeClass("animate");
};

const resetFilters = () => {
  if (!$(".js-select-filter").hasClass("is-disabled")) {
    const removeAllFiltersElement = getRemoveAllFiltersElement();
    if (removeAllFiltersElement) {
      window.location.href = removeAllFiltersElement.href;
    } else if (latestUrl) {
      window.location.href = latestUrl;
    }
    canOpenFilterDialog = true;
    filterChanged = false;

    filtersDialog.close();
    $(filtersDialog).removeClass("animate");
  }
};

const applyFilters = () => {
  if (!$(".js-select-filter").hasClass("is-disabled")) {
    oldStateTemplate = undefined;

    if (curStateTemplate) {
      const productListPart = document.getElementById("product-list-part");
      productListPart.innerHTML =
        curStateTemplate.content.firstElementChild.innerHTML;
      self.pushStateWithInitCallback(
        curStateTemplate.content.firstElementChild.title,
        curStateTemplate.content.firstElementChild.location
      );

      curStateTemplate = undefined;
      filterChanged = false;

      setTimeout(initFilters);
    } else {
      filtersDialog.close();
      $(filtersDialog).removeClass("animate");
    }
    canOpenFilterDialog = true;
  }
};

const initFilters = () => {
  const productListPart = document.getElementById("product-list-part");
  if (!productListPart.getAttribute("inited")) {
    mobileFiltersPlaceHolder = document.getElementById(
      "filter-listing-placeholder-mobile"
    );
    desktopFiltersPlaceHolder = document.getElementById(
      "filter-listing-placeholder-desktop"
    );
    filtersDialogTrigger = document.getElementById("filters-dialog-trigger");
    filtersDialog = document.getElementById("filters-dialog");
    filtersDialogCloseButton = document.getElementById(
      "filters-dialog-close-button"
    );
    resetFiltersButton = document.getElementById("reset-filters");
    applyFiltersButton = document.getElementById("apply-filters");

    handleFilterButtons();

    mobileSortItemsPlaceHolder = document.getElementById(
      "sort-items-placeholder-mobile"
    );
    desktopSortItemsPlaceHolder = document.getElementById(
      "sort-items-placeholder-desktop"
    );
    sortItemsDialogTrigger = document.getElementById(
      "sort-items-dialog-trigger"
    );
    sortItemsDialog = document.getElementById("sort-items-dialog");
    sortItemsDialogCloseButton = document.getElementById(
      "sort-items-dialog-close-button"
    );

    navigationListPlaceHolderDesktop = productListPart.querySelector(
      ".plp-navi-and-filters nav.subNavigation"
    );
    navigationListPlaceHolderMobile = productListPart.querySelector(
      ".plp-listing nav.subNavigation"
    );

    filtersDialogTrigger.addEventListener("click", openFilterDialog);
    sortItemsDialogTrigger.addEventListener("click", openSortItemsDialog);

    const bpMmin = getComputedStyle(document.documentElement).getPropertyValue(
      "--bp-m-min"
    );
    mediaQueryList = matchMedia(`screen and (min-width: ${bpMmin})`);
    mediaQueryList.addEventListener("change", onBreakPointChange);

    productListPart.setAttribute("inited", "");
  }

  onBreakPointChange(mediaQueryList);

  executeRemoveFilterOnce = false;
  $(".js-removeFilter").on("remove", function() {
    if (!executeRemoveFilterOnce) {
      executeRemoveFilterOnce = true;
      canOpenFilterDialog = true;
      setTimeout(initFilters);
    }
  });
};

const createFiltersBackup = () => {
  if (!oldStateTemplate) {
    oldStateTemplate = document.createElement("template");
    const div = document.createElement("div");
    oldStateTemplate.content.appendChild(div);
  }

  const productListPart = document.getElementById("product-list-part");
  oldStateTemplate.content.firstElementChild.innerHTML =
    productListPart.innerHTML;
  oldStateTemplate.content.firstElementChild.location = window.location.href;
  oldStateTemplate.content.firstElementChild.title = document.title;
};

const replaceFilterParts = $list_part => {
  if (!curStateTemplate) {
    curStateTemplate = document.createElement("template");
    const div = document.createElement("div");
    curStateTemplate.content.appendChild(div);
  }

  const productListPart = $list_part[0];
  curStateTemplate.content.firstElementChild.innerHTML =
    productListPart.innerHTML;

  while (mobileFiltersPlaceHolder.firstElementChild) {
    mobileFiltersPlaceHolder.removeChild(
      mobileFiltersPlaceHolder.firstElementChild
    );
  }

  const elem = productListPart.querySelector(
    "#filter-listing-placeholder-desktop"
  );
  while (elem.firstElementChild) {
    mobileFiltersPlaceHolder.appendChild(elem.firstElementChild);
  }

  handleFilterButtons();

  applyFiltersButton.querySelector(
    ".product-count"
  ).innerHTML = productListPart.querySelector(
    ".plp-listing .product-count"
  ).innerHTML;
};

const replaceFilterPartsPostAction = ajaxUrl => {
  curStateTemplate.content.firstElementChild.location = ajaxUrl;
  curStateTemplate.content.firstElementChild.title = document.title;
};

function handleFilterButtons() {
  let removeAllFiltersElement = getRemoveAllFiltersElement();
  if (removeAllFiltersElement || filterChanged) {
    resetFiltersButton.removeAttribute("disabled");
  } else {
    resetFiltersButton.setAttribute("disabled", "");
  }
}

function getRemoveAllFiltersElement() {
  const productListPart = document.getElementById("product-list-part");
  return productListPart.querySelector("#remove-all-filters");
}

let self;

export default {
  init: function() {
    self = this;
    self.bindEvents();
    self.initBrandSuggest();
    self.pushProductsToDatalayer();
    lazyload.init({
      selector: ".js-unveil-list"
    });
    self.ajaxFiltering();
    History.Adapter.bind(
      window,
      "statechange",
      this.createLoadDataOnHistoryChangeFunction
    );
    self.checkReloading();

    initFilters();

    canOpenFilterDialog = true;
    historyState = undefined;
  },

  determinePageType: function() {
    let currentLocation = window.location;
    if (currentLocation.pathname.includes("/search/")) {
      return "search";
    }
    return "category";
  },

  isJsonEmpty: function(json) {
    return JSON.stringify(json) === JSON.stringify({});
  },

  createLoadDataOnHistoryChangeFunction: function() {
    const state = History.getState(); // Note: We are using History.getState() instead of event.state
    // add marker param ajax for sending to Category Ajax Handler
    const ajaxUrl = state.url;

    $.ajax({
      url: ajaxUrl,
      success: function(filterResponse) {
        const $pageHtml = $(filterResponse);

        const $list_part = $pageHtml.find("#product-list-part");
        if (!filtersDialog.open) {
          $("#product-list-part").replaceWith($list_part);
        } else {
          replaceFilterParts($list_part);
        }

        const $breadcrumb = $pageHtml.find("#breadcrumb-listing");
        if (typeof $breadcrumb != null && $breadcrumb.length !== 0) {
          $("#breadcrumb-listing").replaceWith($breadcrumb);
        }

        const $head = $("head");
        $head.find('[data-update^="update"]').remove();

        $.each($pageHtml.filter('[data-update^="update"]'), function() {
          $head.append($(this));
        });

        if (filtersDialog.open) {
          replaceFilterPartsPostAction(ajaxUrl);
        }
      },
      complete: function() {
        lazyload.init({
          selector: ".js-unveil-list"
        });
        self.initBrandSuggest();
        global.contentToggle();
        $(".selector").SnsSelector();
        $(".badge").css({
          opacity: 1
        });
        $(".js-select-filter").toggleClass("is-disabled", false);
        self.pushProductsToDatalayer();
        rangeFilterSlider.init();

        if (!filtersDialog.open) {
          self.pushState(document.title, ajaxUrl);
        }
      },
      error: function(error) {
        if (window.console) {
          window.console.log(error);
        }
      }
    });
  },

  checkReloading: function() {
    if (sessionStorage.getItem("filtering") === "true") {
      $(document).ready(function() {
        sessionStorage.setItem("filtering", null);
        global.handleSticky(500);
      });
    }
  },

  ajaxFiltering: function() {
    $(document).on("click", ".js-select-filter a, .js-sort-by a", function(e) {
      e.preventDefault();
      if (!$(this).hasClass("disabled")) {
        $(this)
          .find(".js-checkbox")
          .toggleClass("checked");
        $(".js-select-filter").toggleClass("is-disabled", true);
        let href = $(this).attr("href")
          ? $(this).attr("href")
          : $(this).attr("data-href");
        self.pushState(document.title, href);
        sessionStorage.setItem("filtering", "true");

        filterChanged =
          typeof oldStateTemplate !== "undefined" &&
          oldStateTemplate.content.firstElementChild.location !==
            window.location.href;
      }
    });

    $(document).on("click", ".js-removeFilter", function(e) {
      e.preventDefault();
      canOpenFilterDialog = false;
      let href = $(this).attr("href")
        ? $(this).attr("href")
        : $(this).attr("data-href");
      self.pushState(document.title, href);
      sessionStorage.setItem("filtering", "true");
    });

    $(document).on("click", ".js-list-pager a", function(e) {
      e.preventDefault();
      let $li = $(this).closest("li");
      if (!$li.hasClass("active") && !$li.hasClass("disabled")) {
        let url = $(this).attr("href");
        self.pushState(document.title, url);
        $("html, body").animate(
          {
            scrollTop: $("#product-list-part").offset().top
          },
          200
        );
      }
    });
  },

  pushProductsToDatalayer: function() {
    let productCards = document.querySelectorAll(".productCard");
    let itemIds = [];
    for (let productCard of productCards) {
      itemIds.push(productCard.dataset.itemId);
    }

    let pageType = self.determinePageType();
    let productBoxContainer = document.getElementById("product box container");
    if (!productBoxContainer) {
      return;
    }

    const pushData = ajaxUrl => {
      $.ajax({
        url: ajaxUrl,
        method: "POST",
        data: JSON.stringify({ pageType: pageType, itemIds: itemIds }),
        dataType: "json",
        contentType: "application/json",
        success: function(jsonData) {
          if (!self.isJsonEmpty(jsonData)) {
            dataLayer.push(jsonData);
          }
        },
        error: function(event) {
          if (window.console) {
            window.console.log(event);
          }
        }
      });
    };

    pushData(productBoxContainer.getAttribute("data-ajax-datalayer-url"));
    pushData(
      productBoxContainer.getAttribute("data-ajax-datalayer-view-event-url")
    );
  },

  bindEvents: function() {
    $(document).on("mouseenter", ".js-location-pin", function() {
      let text = $(".location-pin-clone").text();
      $(this).attr("data-tooltip", text);
    });
  },

  initBrandSuggest: function() {
    $.extend($.ui.autocomplete.prototype, {
      _renderItem: function(ul, item) {
        let term = this.element.val(),
          regex = new RegExp("(" + term + ")", "gi");
        let html = item.label.replace(regex, "<strong>$&</strong>");
        return $("<li></li>")
          .data("item.autocomplete", item)
          .append(html)
          .appendTo(ul);
      }
    });

    $(".js-filterBrandList").each(function() {
      let $brandList = $(this).find(".js-checkbox");
      if ($brandList.length > 0) {
        let brands = $.map($brandList, function(item) {
          return {
            value: $(item).attr("data-value"),
            data: $(item).attr("data-index")
          };
        });
        let $wrapper = $(this).find(".js-autocomplete-brand-wrapper");
        $(this)
          .find(".js-brand-suggest")
          .autocomplete({
            source: brands,
            classes: {
              "ui-autocomplete": "autocomplete-wrapper"
            },
            appendTo: $wrapper,
            select: function(event, ui) {
              let $filterbox = $(this).closest(".js-filterBrandList");
              let $target = $filterbox.find(
                ".js-checkbox[data-index=" + ui.item.data + "]"
              );
              if (!$target.hasClass("checked")) {
                $target.click();
              }
            }
          });
      }
    });
  },

  pushState: function(title, href) {
    if (historyState) {
      // It may be important to restore the previous function if pushStateWithInitCallback is interrupted before the callback call.
      // This can again lead to inconsistencies. To prevent this, the content of the callback is executed in this way for this case.
      setTimeout(initFilters);
      setTimeout(showPlpTextImageTeaserToggle);
      History.pushState = historyState;
      historyState = undefined;
    }
    History.pushState({}, title, href);
  },

  pushStateWithInitCallback: function(title, href) {
    historyState = History.pushState;
    History.pushState = function() {
      setTimeout(initFilters);
      setTimeout(showPlpTextImageTeaserToggle);
      // The previous function must be restored as soon as the callback has run through. Otherwise there will be problems with further pushStates afterwards.
      History.pushState = historyState;
    };
    History.pushState({}, title, href);
  }
};
